import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import PaddingExample from '../src/examples/PaddingExample';
import MarginExample from '../src/examples/MarginExample';
import PopoverExample from '../src/examples/PopoverExample';
import { margin100, margin200, margin300, margin400, margin500, margin600, margin700, margin800, margin900, margin1000 } from "../src/margins";
import { padding100, padding200, padding300, padding400, padding600, padding800 } from "../src/paddings";
import { Container, Col, Row } from "../src/grid";
import { Spacer } from "../src/Spacer";
import Popover from "../src/Popover";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">









    <h1 {...{
      "id": "layout"
    }}>{`Layout`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <p>{`This guideline part has the core layout principles and values.`}</p>
    <h2 {...{
      "id": "screen-sizes"
    }}>{`Screen Sizes`}</h2>
    <p>{`There are five different screen sizes.`}</p>
    <ul>
      <li parentName="ul">{`XS, a.k.a Mobile Small: 0px -> 359px`}</li>
      <li parentName="ul">{`SM, a.k.a Mobile Standard: 360px -> 416px`}</li>
      <li parentName="ul">{`MD, a.k.a Tablet: 417px -> 1024px`}</li>
      <li parentName="ul">{`LG, a.k.a Small Laptop: 1025px -> 1280px`}</li>
      <li parentName="ul">{`XL, a.k.a Laptop: 1281px -> 1440px`}</li>
      <li parentName="ul">{`XXL, a.k.a Desktop: 1441 -> 1920px`}</li>
    </ul>
    <h2 {...{
      "id": "media-queries"
    }}>{`Media Queries`}</h2>
    <p>{`Write in "Mobile First": without a media query you define the smallest screen.
You can then add more elements with `}<inlineCode parentName="p">{`media.min[size]`}</inlineCode>{` query, see example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import styled from "styled-components";
import {media} from "@happeouikit/layout

const StyledDiv = styled.div\`
  width: 100%;
  \${media.min.md\`
    max-width: 1812px;
  \`}
\`;
`}</code></pre>
    <p>{`To only show something on exact screen size use `}<inlineCode parentName="p">{`media.only[size]`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`const StyledDiv = styled.div\`
  width: 100%;
  \${media.only.md\`
    display: none;
  \`}
\`;
`}</code></pre>
    <p><strong parentName="p">{`NOTE:`}</strong>{` For tricky situations there is also `}<inlineCode parentName="p">{`media.max[size]`}</inlineCode>{`, but please prefer the Mobile First approach,
and use the minimum value to keep the code consistent.`}</p>
    <h2 {...{
      "id": "grid"
    }}>{`Grid`}</h2>
    <p>{`Grid is based on `}<a parentName="p" {...{
        "href": "https://getbootstrap.com/docs/4.2/layout/grid/"
      }}>{`Bootstrap 12 column grid`}</a>{`.
See Bootstrap documentation for extensive amount of examples. Basically the Bootstrap classes has been transformed to React Components:
`}<inlineCode parentName="p">{`<Container>`}</inlineCode>{`, `}<inlineCode parentName="p">{`<Row>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<Col>`}</inlineCode>{`. Each of the components takes in the modifications as parameters, not with
classes as in Bootstrap documentation.`}</p>
    <h3 {...{
      "id": "for-example"
    }}>{`For example:`}</h3>
    <Playground __position={1} __code={'<Container>\n  <Row>\n    <Col xs={3} xs={2}>\n      <div style={{ backgroundColor: \'rgba(237,70,71,0.08)\' }}>A</div>\n    </Col>\n    <Col xs={3} md={4}>\n      <div style={{ backgroundColor: \'rgba(237,70,71,0.08)\' }}>B</div>\n    </Col>\n  </Row>\n</Container>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      PaddingExample,
      MarginExample,
      PopoverExample,
      margin100,
      margin200,
      margin300,
      margin400,
      margin500,
      margin600,
      margin700,
      margin800,
      margin900,
      margin1000,
      padding100,
      padding200,
      padding300,
      padding400,
      padding600,
      padding800,
      Container,
      Col,
      Row,
      Spacer,
      Popover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Container mdxType="Container">
    <Row mdxType="Row">
      <Col xs={3} xs={2} mdxType="Col"><div style={{
              backgroundColor: "rgba(237,70,71,0.08)"
            }}>A</div></Col>
      <Col xs={3} md={4} mdxType="Col"><div style={{
              backgroundColor: "rgba(237,70,71,0.08)"
            }}>B</div></Col>
    </Row>
  </Container>
    </Playground>
    <blockquote>
      <p parentName="blockquote">{`If you want to have for example a fixed width sidebar and then columns for content. Do not use grid for the sidebar+content layout, but use
custom elements with fixed with. Inside the sidebar and/or content you can then use a grid.`}</p>
    </blockquote>
    <h3 {...{
      "id": "container-"
    }}><inlineCode parentName="h3">{`<Container />`}</inlineCode></h3>
    <Props of={Container} mdxType="Props" />
    <h3 {...{
      "id": "row-"
    }}><inlineCode parentName="h3">{`<Row />`}</inlineCode></h3>
    <Props of={Row} mdxType="Props" />
    <h3 {...{
      "id": "col-"
    }}><inlineCode parentName="h3">{`<Col />`}</inlineCode></h3>
    <Props of={Col} mdxType="Props" />
    <h2 {...{
      "id": "margins"
    }}>{`Margins`}</h2>
    <MarginExample margin={{
      margin100
    }} mdxType="MarginExample" />
    <MarginExample margin={{
      margin200
    }} mdxType="MarginExample" />
    <MarginExample margin={{
      margin300
    }} mdxType="MarginExample" />
    <MarginExample margin={{
      margin400
    }} mdxType="MarginExample" />
    <MarginExample margin={{
      margin500
    }} mdxType="MarginExample" />
    <MarginExample margin={{
      margin600
    }} mdxType="MarginExample" />
    <MarginExample margin={{
      margin700
    }} mdxType="MarginExample" />
    <MarginExample margin={{
      margin800
    }} mdxType="MarginExample" /> 
    <MarginExample margin={{
      margin900
    }} mdxType="MarginExample" /> 
    <MarginExample margin={{
      margin1000
    }} mdxType="MarginExample" /> 
    <h2 {...{
      "id": "paddings"
    }}>{`Paddings`}</h2>
    <PaddingExample padding={{
      padding100
    }} mdxType="PaddingExample" />
    <PaddingExample padding={{
      padding200
    }} mdxType="PaddingExample" />
    <PaddingExample padding={{
      padding300
    }} mdxType="PaddingExample" />
    <PaddingExample padding={{
      padding400
    }} mdxType="PaddingExample" />
    <PaddingExample padding={{
      padding600
    }} mdxType="PaddingExample" />
    <PaddingExample padding={{
      padding800
    }} mdxType="PaddingExample" />
    <h2 {...{
      "id": "spacers"
    }}>{`Spacers`}</h2>
    <p>{`Spacer component accepts width and height, both will default to 16px if value not given.`}</p>
    <Playground __position={7} __code={'<Container>\n  <Row>\n    <div style={{ backgroundColor: \'rgba(237,70,71,0.08)\', padding: \'8px\' }}>\n      Default\n    </div>\n    <Spacer />\n    <div style={{ backgroundColor: \'rgba(237,70,71,0.08)\', padding: \'8px\' }}>\n      /Default\n    </div>\n  </Row>\n  <Row>\n    <div style={{ backgroundColor: \'rgba(237,70,71,0.08)\', padding: \'8px\' }}>\n      Width 20px\n    </div>\n    <Spacer width={\'20px\'} />\n    <div style={{ backgroundColor: \'rgba(237,70,71,0.08)\', padding: \'8px\' }}>\n      /Width 20px\n    </div>\n  </Row>\n  <Row>\n    <div style={{ backgroundColor: \'rgba(237,70,71,0.08)\', padding: \'8px\' }}>\n      Height 60px\n    </div>\n    <Spacer height={\'60px\'} />\n    <div style={{ backgroundColor: \'rgba(237,70,71,0.08)\', padding: \'8px\' }}>\n      /Height 60px\n    </div>\n  </Row>\n  <Row>\n    <div style={{ backgroundColor: \'rgba(237,70,71,0.08)\', padding: \'8px\' }}>\n      Height, Width 40px\n    </div>\n    <Spacer width={\'40px\'} height={\'40px\'} />\n    <div style={{ backgroundColor: \'rgba(237,70,71,0.08)\', padding: \'8px\' }}>\n      /Height, Width 40px\n    </div>\n  </Row>\n</Container>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      PaddingExample,
      MarginExample,
      PopoverExample,
      margin100,
      margin200,
      margin300,
      margin400,
      margin500,
      margin600,
      margin700,
      margin800,
      margin900,
      margin1000,
      padding100,
      padding200,
      padding300,
      padding400,
      padding600,
      padding800,
      Container,
      Col,
      Row,
      Spacer,
      Popover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Container mdxType="Container">
    <Row mdxType="Row">
      <div style={{
            backgroundColor: "rgba(237,70,71,0.08)",
            padding: "8px"
          }}>Default</div>
      <Spacer mdxType="Spacer" />
      <div style={{
            backgroundColor: "rgba(237,70,71,0.08)",
            padding: "8px"
          }}>/Default</div>
    </Row>
    <Row mdxType="Row">
      <div style={{
            backgroundColor: "rgba(237,70,71,0.08)",
            padding: "8px"
          }}>Width 20px</div>
      <Spacer width={"20px"} mdxType="Spacer" />
      <div style={{
            backgroundColor: "rgba(237,70,71,0.08)",
            padding: "8px"
          }}>/Width 20px</div>
    </Row>
    <Row mdxType="Row">
      <div style={{
            backgroundColor: "rgba(237,70,71,0.08)",
            padding: "8px"
          }}>Height 60px</div>
      <Spacer height={"60px"} mdxType="Spacer" />
      <div style={{
            backgroundColor: "rgba(237,70,71,0.08)",
            padding: "8px"
          }}>/Height 60px</div>
    </Row>
    <Row mdxType="Row">
      <div style={{
            backgroundColor: "rgba(237,70,71,0.08)",
            padding: "8px"
          }}>Height, Width 40px</div>
      <Spacer width={"40px"} height={"40px"} mdxType="Spacer" />
      <div style={{
            backgroundColor: "rgba(237,70,71,0.08)",
            padding: "8px"
          }}>/Height, Width 40px</div>
    </Row>
  </Container>
    </Playground>
    <h2 {...{
      "id": "popover"
    }}>{`Popover`}</h2>
    <p>{`Popover allows to create elements that float in reference to a anchor element. You can also
use the popover ref to add `}</p>
    <Playground __position={8} __code={'<Container>\n  <PopoverExample text=\"Top left\" anchorPosition=\"top left\" />\n  <PopoverExample text=\"Top right\" anchorPosition=\"top right\" />\n  <PopoverExample text=\"Bottom left\" anchorPosition=\"bottom left\" />\n  <PopoverExample text=\"Bottom right\" anchorPosition=\"bottom right\" />\n</Container>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      PaddingExample,
      MarginExample,
      PopoverExample,
      margin100,
      margin200,
      margin300,
      margin400,
      margin500,
      margin600,
      margin700,
      margin800,
      margin900,
      margin1000,
      padding100,
      padding200,
      padding300,
      padding400,
      padding600,
      padding800,
      Container,
      Col,
      Row,
      Spacer,
      Popover,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Container mdxType="Container">
   <PopoverExample text="Top left" anchorPosition="top left" mdxType="PopoverExample" />
   <PopoverExample text="Top right" anchorPosition="top right" mdxType="PopoverExample" />
   <PopoverExample text="Bottom left" anchorPosition="bottom left" mdxType="PopoverExample" />
   <PopoverExample text="Bottom right" anchorPosition="bottom right" mdxType="PopoverExample" />
  </Container>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <ul>
      <li parentName="ul">{`anchor = Anchor element ref`}</li>
      <li parentName="ul">{`anchorPosition =  "top left" | "top right" | "bottom left" | "bottom right"`}</li>
      <li parentName="ul">{`children = Child elements`}</li>
    </ul>
    <h3 {...{
      "id": "popover-code-example-with-useonclickoutside"
    }}>{`Popover code example with useOnClickOutside`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const PopoverExample = () => {
  const [isOpen, setIsOpen] = useState(false);
  const anchor = useRef();
  const popover = useRef();

  hooks.useOnClickOutside(popover, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  return (
    <div ref={anchor}>
      <button
        type="button"
        onClick={() => setIsOpen((prevValue) => !prevValue)}
      >
        clickedi
      </button>
      {isOpen && (
        <Popover anchor={anchor} ref={popover}>
          <div>
            <p>I am in a popover lol</p>
          </div>
        </Popover>
      )}
    </div>
  );
};

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      